import cx from 'classnames'
import { ReactNode } from 'react'
import useIsNavigating from '../../next/useIsNavigating'

interface Props {
  children: ReactNode
}

export default (props:Props) => {
  const isNavigating:boolean = useIsNavigating()

  return (
    <div className={cx('App', isNavigating && 'App--navigating')}>
      {props.children}
    </div>
  )
}
